import "./Menu.css";
import MenuItem from "./MenuItem.js";
import { data } from "../../buttons.js";

function Menu({ changeMode, toggler }) {
    return (
        <div className="menu-container">
            {data.map((each) => (
                <MenuItem
                    key={each.index}
                    name={each.name}
                    msg={each.click}
                    changeMode={changeMode}
                    link={each.link}
                    toggler={toggler}
                    shape={each.shape}
                />
            ))}
        </div>
    );
}

export default Menu;
