const data = [
    {
        name: "FEED",
        index: 0,
        link: "/feed",
        click: "",
        shape: "rectangle",
    },
    {
        name: "LABEL",
        index: 1,
        link: "/label",
        click: "",
        shape: "rectangle",
    },
    {
        name: "BOOK NOW",
        index: 2,
        link: "/booknow",
        click: "",
        shape: "circle",
    },
    {
        name: "TALENTS",
        index: 3,
        link: "/talents",
        click: "",
        shape: "rectangle",
    },

    {
        name: "CONTACT",
        index: 4,
        link: "/contact",
        click: "",
        shape: "rectangle",
    },
];

exports.data = data;
