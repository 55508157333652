import "./App.css";
import Logo from "./components/Global/Logo.js";
import Feed from "./components/Feed/Feed.js";
import Social from "./components/Global/Social.js";
import SocialExtended from "./components/Global/SocialExtended.js";
import Menu from "./components/Global/Menu.js";
import Talents from "./components/Talents/Talents.js";
import BookNow from "./components/BookNow/BookNow.js";
import Contact from "./components/Contact/Contact.js";
import ArtistMaxi from "./components/Talents/ArtistMaxi.js";
import Navbar from "./components/Mobile/Navbar.js";
import Admin from "./components/Admin/Admin.js";
import Label from "./components/Label/Label.js";

import { Route, Switch, Redirect } from "react-router-dom";
import { useEffect, useState } from "react";
import { ConnectContactLens } from "aws-sdk";

function App() {
    const [darkmode, setDarkmode] = useState("");
    const [contact, setContact] = useState(false);
    const [backgroundPosition, setBackgroundPosition] = useState("three");

    const changeMode = () => {
        if (!darkmode) {
            setDarkmode(" darkmode");
        } else {
            setDarkmode("");
        }
    };

    useEffect(() => {
        console.log("chosen contact: ", contact);
    }, [contact]);

    return (
        <div className={darkmode}>
            <div className="app">
                <div className="app-navbar">
                    <Navbar changeMode={changeMode} />
                </div>
                <div className="app-top">
                    <div
                        className={`app-background ${backgroundPosition}`}
                    ></div>
                    <div className="app-top-left">
                        <Logo src="LogoNeon.svg" />
                    </div>
                    <div className="app-top-middle">
                        <Switch>
                            <Route
                                exact
                                path="/feed"
                                render={() => (
                                    <Feed
                                        setBackgroundPosition={
                                            setBackgroundPosition
                                        }
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/talents"
                                render={() => (
                                    <Talents
                                        setContact={setContact}
                                        setBackgroundPosition={
                                            setBackgroundPosition
                                        }
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/booknow"
                                render={() => (
                                    <BookNow
                                        contact={contact}
                                        setContact={setContact}
                                        setBackgroundPosition={
                                            setBackgroundPosition
                                        }
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/contact"
                                render={() => (
                                    <Contact
                                        setBackgroundPosition={
                                            setBackgroundPosition
                                        }
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/label"
                                render={() => (
                                    <Label
                                        setBackgroundPosition={
                                            setBackgroundPosition
                                        }
                                    />
                                )}
                            />

                            <Route
                                exact
                                path="/admin"
                                render={() => (
                                    <Admin
                                        setBackgroundPosition={
                                            setBackgroundPosition
                                        }
                                    />
                                )}
                            />
                            <Route
                                path="/talents/:id"
                                render={(props) => (
                                    <ArtistMaxi
                                        key={props.match.url}
                                        match={props.match}
                                        history={props.history}
                                    />
                                )}
                            />
                            <Redirect to="/feed" />
                        </Switch>
                    </div>
                    <div className="app-top-right">
                        <Social />
                    </div>
                </div>
                <div className="app-bottom">
                    <Menu changeMode={changeMode} />
                </div>
            </div>
        </div>
    );
}

export default App;
