import { useState, useEffect } from "react";
import "./Feed.css";
import axios from "axios";
import SingleFeed from "./SingleFeed.js";
import NewFeed from "./NewFeed";
import EditFeed from "./EditFeed";

function Feed({ handleClickPanel, setFeed }) {
    const [feedData, setFeedData] = useState([]);
    const [minIdInDb, setMinIdInDb] = useState();
    const [maxIdInDb, setMaxIdInDb] = useState();
    const [refresh, setRefresh] = useState();
    const [isEdit, setIsEdit] = useState();

    useEffect(() => {
        (async () => {
            const { data } = await axios("/api/feed");
            setFeedData(data.rows);
            if (data.rows[0]) {
                setMinIdInDb(data.rows[0].lowestPosition);
                setMaxIdInDb(data.rows[0].highestPosition);
            }
            console.log("max and min: ", data.rows);
        })();
    }, [refresh]);

    return (
        <>
            <div className="feed-admin-container">
                <div className="feed-admin-left">
                    <p className="feed-par">All online feed posts</p>
                    {feedData &&
                        feedData.map((each) => (
                            <SingleFeed
                                key={each.id}
                                id={each.id}
                                title={each.title}
                                url={each.url}
                                image={each.image}
                                tags={each.tags}
                                text={each.text}
                                sc={each.sc}
                                bc={each.bc}
                                ra={each.ra}
                                ig={each.ig}
                                sp={each.sp}
                                fb={each.fb}
                                yt={each.yt}
                                dc={each.dc}
                                ourl={each.ourl}
                                position={each.position}
                                timestamp={each.timestamp}
                                setIsEdit={setIsEdit}
                                min={minIdInDb}
                                max={maxIdInDb}
                                refresh={setRefresh}
                                handleClickPanel={handleClickPanel}
                                setFeed={setFeed}
                            />
                        ))}
                </div>

                <div className="feed-admin-right">
                    {!isEdit && <NewFeed handleClickPanel={handleClickPanel} />}
                    {isEdit && (
                        <EditFeed
                            feed={isEdit}
                            key={isEdit.id}
                            handleClickPanel={handleClickPanel}
                        />
                    )}
                </div>
            </div>
        </>
    );
}

export default Feed;
