import { useState, useRef } from "react";
import "./NewArtist.css";
import axios from "axios";

function NewArtist({ handleClickPanel }) {
    const [state, setState] = useState({
        name: "",
        url: "",
        image: "",
        role: "",
        bio: "",
        sc: "",
        bc: "",
        ra: "",
        ig: "",
        sp: "",
        fb: "",
        yt: "",
        dc: "",
        pdf: "",
    });

    const fileInput = useRef(null);
    const fileInputTwo = useRef(null);
    const fileInputMini = useRef(null);

    const fileButtonClick = (e) => {
        e.preventDefault();
        fileInput.current.click();
        console.log(state);
    };
    const fileButtonClickTwo = (e) => {
        e.preventDefault();
        fileInputTwo.current.click();
        console.log(state);
    };
    const fileButtonClickMini = (e) => {
        e.preventDefault();
        fileInputMini.current.click();
        console.log(state);
    };

    const handleChange = (e) => {
        console.log("State: ", state);

        setState({
            ...state,
            [e.target.name]: e.target.value,
            error: false,
        });
    };

    const handleImage = (e) => {
        setState({ ...state, image: e.target.files[0] });
        console.log("state image: ", state.image);
    };

    const handlePdf = (e) => {
        setState({ ...state, pdf: e.target.files[0] });
        console.log("state image: ", state.pdf);
    };
    const handleImageMini = (e) => {
        setState({ ...state, imagemini: e.target.files[0] });
        console.log("state image: ", state.imagemini);
    };

    const handleClick = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append(`image`, state.image);
        formData.append(`pdf`, state.pdf);
        formData.append(`imagemini`, state.imagemini);
        formData.append("name", state.name);
        formData.append("url", state.url);
        formData.append("role", state.role);
        formData.append("bio", state.bio);
        formData.append("sc", state.sc);
        formData.append("bc", state.bc);
        formData.append("ra", state.ra);
        formData.append("ig", state.ig);
        formData.append("sp", state.sp);
        formData.append("fb", state.fb);
        formData.append("yt", state.yt);
        formData.append("dc", state.dc);

        const { data } = await axios.post("/api/superuser/newartist", formData);

        handleClickPanel("Panel");
    };

    return (
        <form className="new-artist-form" enctype="multipart/form-data">
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="name" className="new-artist-label">
                        Name:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="name"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>

            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="url" className="new-artist-label">
                        Url:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="url"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="image" className="new-artist-label">
                        Image:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        ref={fileInput}
                        name="image"
                        onChange={handleImage}
                        type="file"
                        className="new-artist-input new-artist-file"
                    />
                    <div className="new-artist-file-button-div">
                        <button
                            className="new-artist-upload-btn"
                            onClick={fileButtonClick}
                        >
                            File
                        </button>
                        {state.image && (
                            <p id="file-link-name">{state.image.name}</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="imagemini" className="new-artist-label">
                        Miniature:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        ref={fileInputMini}
                        name="imagemini"
                        onChange={handleImageMini}
                        type="file"
                        className="new-artist-input new-artist-file"
                    />
                    <div className="new-artist-file-button-div">
                        <button
                            className="new-artist-upload-btn"
                            onClick={fileButtonClickMini}
                        >
                            File
                        </button>
                        {state.imagemini && (
                            <p id="file-link-name">{state.imagemini.name}</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="role" className="new-artist-label">
                        Role:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="role"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="bio" className="new-artist-label">
                        Bio:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    {/* <input
                        name="bio"
                        onChange={handleChange}
                        className="new-artist-input textarea"
                    /> */}
                    <textarea
                        name="bio"
                        onChange={handleChange}
                        className="new-artist-input textarea"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="sc" className="new-artist-label">
                        Soundcloud:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="sc"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>

            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="bc" className="new-artist-label">
                        BandCamp:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="bc"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>

            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="ra" className="new-artist-label">
                        Resident Advisor:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="ra"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="ig" className="new-artist-label">
                        Instagram:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="ig"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="sp" className="new-artist-label">
                        Spotify:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="sp"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="fb" className="new-artist-label">
                        Facebook:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="fb"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="yt" className="new-artist-label">
                        Youtube:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="yt"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="dc" className="new-artist-label">
                        Discogs:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="dc"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>

            {/* <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="pdf" className="new-artist-label">
                        PDF:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        name="pdf"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div> */}

            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="image" className="new-artist-label">
                        PDF:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        ref={fileInputTwo}
                        name="pdf"
                        onChange={handlePdf}
                        type="file"
                        className="new-artist-input new-artist-file"
                    />
                    <div className="new-artist-file-button-div">
                        <button
                            className="new-artist-upload-btn"
                            onClick={fileButtonClickTwo}
                        >
                            File
                        </button>
                        {state.pdf && (
                            <p id="file-link-name">{state.pdf.name}</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="new-artist-submit">
                <button
                    className="new-artist-submit-button"
                    onClick={handleClick}
                >
                    Submit
                </button>
            </div>
        </form>
    );
}

export default NewArtist;
