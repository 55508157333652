import { useState, useRef, useEffect } from "react";
import "./Contact.css";
import FormLine from "./FormLine.js";
import axios from "axios";
import EmailConfirmation from "../Reusable/EmailConfirmation.js";

function Contact({ setBackgroundPosition }) {
    const form = useRef(null);
    const [state, setState] = useState({ name: "", email: "", message: "" });
    const [sent, setSent] = useState(false);
    const [transition, setTransition] = useState();
    const scroll = useRef(null);

    useEffect(() => {
        setBackgroundPosition("five");
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setTransition("contact-container-entered");
        }, 800);
    }, []);

    useEffect(() => {
        scroll.current.scrollIntoView();
    }, []);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
            error: false,
        });
        console.log("state: ", state);
    };

    const handleClick = async (e) => {
        e.preventDefault();
        const isValid = form.current.reportValidity();

        if (isValid) {
            const { data } = await axios.post("/api/contact", {
                name: state.name,
                email: state.email,
                message: state.message,
            });
            setState({ name: "", email: "", message: "" });
            setSent(true);
        }
    };

    return (
        <div className="contact-background">
            <div className={`contact-container ${transition}`}>
                <div className="contact-content" ref={scroll}>
                    <p id="contact-first">
                        This is our motion to design a space for like-minded
                        creators and challenge the insufferable state of
                        puppetry in electronic music.
                    </p>
                    <p>
                        Kicks & Hugs perpetual approach is forged for compatible
                        minds, to dismantle old structures and resist
                        capitulation to conformity.
                    </p>
                    <p>
                        Disrupting a long-lasting burden that corrupts the
                        kinetic flow of ideas, our attitude fosters creativity
                        in various mediums, promoting an intersectional
                        crossroads of an insubordinate movement. Our vision
                        knows no limits.
                    </p>
                    <p>
                        K&H is a wide spectrum platform that includes but is not
                        limited to a booking agency, record label, event
                        promotion and more.
                    </p>
                    <p id="contact-last">
                        As there are neither directives nor guidelines, in the
                        absence of resistance we are the ones paving out
                        individual paths. 
                    </p>
                    <h1 className="contact-questions question-animation">
                        Questions?
                    </h1>

                    <div id="contact-form-container">
                        <form id="contact-form" ref={form}>
                            <FormLine
                                name={"name"}
                                label="Name:"
                                handleChange={handleChange}
                                fieldValue={state.name}
                            />
                            <FormLine
                                name={"email"}
                                label="Email:"
                                handleChange={handleChange}
                                fieldValue={state.email}
                            />
                            <div id="contact-message">
                                <textarea
                                    id="contact-message-textarea"
                                    name="message"
                                    value={state.message}
                                    onChange={handleChange}
                                    required
                                />
                            </div>

                            <div id="contact-send-outter">
                                <input
                                    id="contact-send-inner"
                                    type="submit"
                                    value="Send"
                                    onClick={handleClick}
                                />
                            </div>
                        </form>
                    </div>
                    <div id="subscribe">
                        <a
                            id="subscribe-link"
                            href="https://mailchi.mp/4324586a4aa0/kicks-hugs"
                            rel="noreferrer"
                            target="_blank"
                        >
                            <div id="contact-send-outter-subscribe">
                                <input
                                    id="contact-send-inner-subscribe"
                                    type="submit"
                                    value="Subscribe"
                                />
                            </div>
                        </a>
                        <p>Stay up to date with a quarterly newsletter!</p>
                    </div>
                </div>

                {sent && (
                    <EmailConfirmation handleClick={() => setSent(false)} />
                )}
                <p></p>
            </div>
        </div>
    );
}

export default Contact;
