import { useEffect, useState } from "react";
import "./ArtistMaxi.css";
import axios from "axios";
import { Link } from "react-router-dom";
import ArtistLinks from "../Reusable/ArtistLinks";
import MoreTalents from "./MoreTalents.js";
import { Transition } from "react-transition-group";

function ArtistMaxi(props) {
    const [artistData, setArtistData] = useState();
    const [isTalentPicture, setIsTalentPicture] = useState(false);

    useEffect(() => {
        (async () => {
            const { data } = await axios(
                `/api/artists/${props.match.params.id}`
            );
            setArtistData(data);
        })();
    }, []);

    const [transition, setTransition] = useState();

    useEffect(() => {
        setTimeout(() => {
            setTransition("artists-entered");
        }, 400);
    }, []);

    const handleClick = () => {
        setIsTalentPicture(false);
        console.log(window.innerWidth);
    };
    const handleClickTrue = () => {
        if (window.innerWidth > 600) {
            setIsTalentPicture(true);
        }
    };

    return (
        <div className={`artists-maxi-container`}>
            {artistData && (
                <>
                    <div className={`artists ${transition}`}>
                        <div className="left">
                            <div className="left-first">
                                <img
                                    className="artist-image"
                                    src={artistData.image}
                                    alt="Artist"
                                    onClick={handleClickTrue}
                                ></img>
                                <img
                                    className="artist-image-mobile"
                                    src={artistData.imagemini}
                                    alt="Artist"
                                    onClick={handleClickTrue}
                                ></img>
                            </div>
                            <div className="left-second">
                                <ArtistLinks
                                    sc={artistData.sc}
                                    bc={artistData.bc}
                                    ra={artistData.ra}
                                    ig={artistData.ig}
                                    sp={artistData.sp}
                                    fb={artistData.fb}
                                    yt={artistData.yt}
                                    dc={artistData.dc}
                                    pdf={artistData.pdf}
                                />
                            </div>
                        </div>
                        <div className="right">
                            <div className="right-first">
                                <h1 className="artist-name">
                                    {artistData.name}
                                </h1>
                                <div className="left-third">
                                    <ArtistLinks
                                        sc={artistData.sc}
                                        bc={artistData.bc}
                                        ra={artistData.ra}
                                        ig={artistData.ig}
                                        sp={artistData.sp}
                                        fb={artistData.fb}
                                        yt={artistData.yt}
                                        dc={artistData.dc}
                                        pdf={artistData.pdf}
                                    />
                                </div>
                                {artistData.url !== "jasen-loveland" &&
                                    artistData.url !== "the-lone-flanger" && (
                                        <h2 className="artist-performance">
                                            PERFORMANCE:&nbsp;
                                            {artistData.role}
                                        </h2>
                                    )}
                                {artistData.url === "jasen-loveland" && (
                                    <h2 className="artist-performance">
                                        IN MEMORIAM OF ANDREW SMITH 1980 - 2021
                                    </h2>
                                )}
                                {artistData.url === "the-lone-flanger" && (
                                    <h2 className="artist-performance">
                                        IN MEMORIAM OF ANDREW SMITH 1980 - 2021
                                    </h2>
                                )}
                                <p
                                    className="artist-description"
                                    dangerouslySetInnerHTML={{
                                        __html: artistData.bio,
                                    }}
                                ></p>
                            </div>
                            <div className="right-second">
                                <Link className="link-back" to={"/talents"}>
                                    <div className="back-button">
                                        <i class="fas fa-chevron-circle-left"></i>
                                        <span id="back">Back</span>
                                    </div>
                                </Link>

                                {artistData.url !== "jasen-loveland" &&
                                    artistData.url !== "the-lone-flanger" && (
                                        <Link
                                            className="link-book"
                                            to={"/booknow"}
                                        >
                                            <div className="book-button-div">
                                                <div className="book-button">
                                                    <span id="book">Book</span>
                                                    <i class="fas fa-arrow-circle-right"></i>
                                                </div>
                                            </div>
                                        </Link>
                                    )}
                            </div>
                            <div className="artist-maxi-more-container">
                                <div className="artist-maxi-more">
                                    MORE K&H TALENTS
                                </div>
                                <MoreTalents id={artistData.id} />
                                <p></p>
                            </div>
                        </div>
                    </div>
                    <Transition in={isTalentPicture} timeout={600}>
                        {(state) => (
                            <div
                                className={`talent-picture-container fade fade-${state}`}
                            >
                                <div className={`talent-picture-image-div`}>
                                    <img
                                        src={artistData.image}
                                        className="talent-picture-image"
                                        onClick={handleClick}
                                    />
                                    <i
                                        class="fas fa-times talent-picture-x"
                                        onClick={handleClick}
                                    ></i>
                                </div>
                            </div>
                        )}
                    </Transition>
                </>
            )}
        </div>
    );
}

export default ArtistMaxi;
