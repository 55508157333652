import "./FeedMiniMobile.css";
import ArtistLinks from "../Reusable/ArtistLinks.js";
import { useEffect, useState } from "react";

function FeedMiniMobile({ data, minId }) {
    const [readMore, setReadMore] = useState(true);

    useEffect(() => {
        if (data.text.length > 900) {
            setReadMore(true);
        } else {
            setReadMore(false);
        }
    }, []);

    return (
        <div className="feed-mini-mobile">
            <h1 className="feed-mini-mobile-title">{data.title}</h1>

            <div className="feed-mini-mobile-image-container">
                <img className="feed-mini-mobile-image" src={data.image}></img>
            </div>

            {readMore && (
                <>
                    <span
                        className="feed-mini-text"
                        dangerouslySetInnerHTML={{
                            __html: data.text.substring(0, 900) + "...",
                        }}
                    ></span>
                    <div
                        className="read-more"
                        onClick={() => setReadMore(false)}
                    >
                        Read More >>
                    </div>
                </>
            )}
            {!readMore && (
                <>
                    <span
                        className="feed-mini-text"
                        dangerouslySetInnerHTML={{
                            __html: data.text,
                        }}
                    ></span>
                    {data.text.length > 900 && (
                        <div
                            className="read-more"
                            onClick={() => setReadMore(true)}
                        >
                            Hide
                        </div>
                    )}
                </>
            )}

            <div className="feed-mini-mobile-social">
                <ArtistLinks
                    sc={data.sc}
                    bc={data.bc}
                    dc={data.dc}
                    sp={data.sp}
                    ra={data.ra}
                    ig={data.ig}
                    fb={data.fb}
                    yt={data.yt}
                    ourl={data.ourl}
                />
            </div>
            {data.position !== minId && <hr className="feed-mini-mobile-hr" />}
        </div>
    );
}

export default FeedMiniMobile;
