import "./Talents.css";
import ArtistMini from "./ArtistMini.js";
import { Link } from "react-router-dom";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import TalentsPicture from "./TalentsPicture.js";

function Talents({ setContact, setBackgroundPosition }) {
    const [artistsData, setArtistsData] = useState();
    const [pick, setPick] = useState();
    const [numberArray, setNumberArray] = useState();
    const [transition, setTransition] = useState();
    const [classStatus, setClassStatus] = useState("test");
    const scroll = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setTransition("talents-container-entered");
        }, 800);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setClassStatus("test-visible");
        }, 800);
    }, []);

    useEffect(() => {
        scroll.current.scrollIntoView(0, 0);
    }, []);

    const handleLeft = () => {
        if (numberArray === 0) {
            setPick(artistsData[artistsData.length - 1]);
            setNumberArray(artistsData.length - 1);
            console.log("specjalny przypadek");
        } else {
            setPick(artistsData[numberArray - 1]);
            setNumberArray(numberArray - 1);
            console.log(pick.name, pick.id, numberArray);
        }
    };

    const handleRight = () => {
        if (numberArray === artistsData.length - 1) {
            setPick(artistsData[0]);
            setNumberArray(0);
        } else {
            setPick(artistsData[numberArray + 1]);
            setNumberArray(numberArray + 1);
            console.log(pick.name, pick.id, numberArray);
        }
    };

    useEffect(() => {
        setBackgroundPosition("one");
    }, []);

    useEffect(() => {
        if (artistsData) {
            const number = Math.floor(Math.random() * artistsData.length);
            setNumberArray(number);
            setPick(artistsData[number]);
        }
    }, [artistsData]);

    useEffect(() => {
        (async () => {
            const { data } = await axios("/api/artists");
            setArtistsData(data.rows);
            console.log("downloaded", data);
        })();
    }, []);

    const handleContact = () => {
        if (pick) {
            if (
                pick.url !== "jasen-loveland" &&
                pick.url !== "the-lone-flanger"
            ) {
                setContact(pick.name);
            }
        }
    };

    return (
        <>
            {/* For desktops: */}
            <div className={`talents-container ${transition}`} ref={scroll}>
                <img
                    src="./vibe.png"
                    className="talents-headline vibe-animation"
                />
                <div className="artists-container">
                    {artistsData &&
                        artistsData.map((each, index) => (
                            <Link
                                key={each.id}
                                to={`/talents/${each.url}`}
                                className={classStatus}
                            >
                                <ArtistMini
                                    key={each.id}
                                    name={each.name}
                                    imagemini={each.imagemini}
                                    setContact={setContact}
                                    url={each.url}
                                />
                            </Link>
                        ))}
                </div>
            </div>
            {/* For Mobile: */}
            <div className="talents-mobile-container">
                <img
                    src="./vibe.png"
                    className="talents-headline vibe-animation"
                />
                {pick && (
                    <>
                        <Link
                            to={`/talents/${pick.url}`}
                            className="talents-link"
                            onClick={handleContact}
                        >
                            <TalentsPicture
                                url={pick.imagemini}
                                name={pick.name}
                            />
                        </Link>
                        <div className="talents-mobile-navigation">
                            <div
                                className="talents-nav-button"
                                onClick={handleLeft}
                            >
                                <i className="fas fa-chevron-left"></i>
                            </div>
                            <div className="talents-dots">
                                {artistsData &&
                                    artistsData.map((each) => (
                                        <>
                                            {each.id === pick.id && (
                                                <div className="talents-dot-active"></div>
                                            )}
                                            {each.id !== pick.id && (
                                                <div className="talents-dot"></div>
                                            )}
                                        </>
                                    ))}
                            </div>
                            <div
                                className="talents-nav-button"
                                onClick={handleRight}
                            >
                                <i class="fas fa-chevron-right"></i>
                            </div>
                        </div>
                    </>
                )}
                <p></p>
            </div>
        </>
    );
}

export default Talents;
