import "./Navbar.css";
import Logo from "../Global/Logo.js";
import MenuMobile from "./MenuMobile.js";
import { useEffect, useState } from "react";

function Navbar({ changeMode }) {
    const [status, setStatus] = useState(false);

    const statusChange = () => {
        setStatus(!status);
    };

    return (
        <>
            {status && (
                <MenuMobile toggler={setStatus} changeMode={changeMode} />
            )}
            <div className="navbar-logo-container">
                <Logo src="LogoBiss.svg" />
            </div>
            <div>
                <h1 className="navbar-name">KICKS & HUGS</h1>
            </div>
            <div className="navbar-hamburger" onClick={statusChange}>
                <i className="fas fa-bars"></i>
            </div>
        </>
    );
}

export default Navbar;
