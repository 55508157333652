import { useEffect, useState } from "react";
import "./MoreTalents.css";
import axios from "axios";
import { Link } from "react-router-dom";

function MoreTalents({ id }) {
    const [moreTalents, setMoreTalents] = useState([]);

    useEffect(() => {
        (async () => {
            const { data } = await axios(`/api/more-talents/${id}`);
            setMoreTalents(data.rows);
            console.log(data.rows);
        })();
    }, []);

    return (
        <div className="more-talents-container">
            {moreTalents &&
                moreTalents.map((each) => (
                    <Link
                        to={`/talents/${each.url}`}
                        className="more-talents-link"
                    >
                        <div className="more-talents-picture-div">
                            <img
                                src={each.imagemini}
                                className="more-talents-picture"
                            />
                        </div>

                        <h1 className="more-talents-name">{each.name}</h1>
                    </Link>
                ))}
        </div>
    );
}

export default MoreTalents;
