import "./EmailConfirmation.css";

function EmailConfirmation({ handleClick }) {
    return (
        <div className="email-popup-container">
            <div className="email-popup-box">
                <div className="email-popup-content">
                    <p className="email-popup-message">
                        Thank you for your inquiry,
                        <br /> We'll get back to ya!
                    </p>
                    <div id="email-popup-button-outter">
                        <input
                            id="email-popup-button-inner"
                            value="OK"
                            onClick={handleClick}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EmailConfirmation;
