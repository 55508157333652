import "./MenuItem.css";
import { NavLink, Link } from "react-router-dom";
import { useState, useEffect } from "react";

function MenuItem({ name, msg, changeMode, link, toggler, shape }) {
    const [status, setStatus] = useState("");

    const handleClick = () => {
        changeMode();
    };

    const handleMouseEnter = () => {
        setStatus("transform-up");
    };

    const handleMouseLeave = () => {
        setStatus("");
    };
    const handleMouseEnterCircular = () => {
        setStatus("zoom-in");
    };

    const handleMouseLeaveCircular = () => {
        setStatus("");
    };

    if (msg === "darkmode") {
        return (
            <div
                className="menu-button-regular"
                className={`menu-button-regular ${status}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                onClick={handleClick}
            >
                <div className="menu-button-regular-inner">
                    <span className="button-text">{name}</span>
                </div>
            </div>
        );
    } else if (shape === "circle") {
        return (
            // Version for mobile devices:
            <Link className="link menu-first" to={link}>
                <div
                    className="menu-mobile-toggler"
                    onClick={() => {
                        toggler();
                    }}
                >
                    <div className="menu-button-mobile-circle">
                        <div className="menu-button-mobile-circle-inner">
                            <NavLink
                                className={`button-text`}
                                activeClassName="button-text-active"
                                to={link}
                            >
                                {name}
                            </NavLink>
                        </div>
                    </div>
                </div>

                {/* Version for desktop: */}
                <div className="menu-notmobile">
                    <div
                        className={`menu-button-circular`}
                        onMouseEnter={handleMouseEnterCircular}
                        onMouseLeave={handleMouseLeaveCircular}
                    >
                        <div
                            className={`menu-button-circular-inner-first ${status}`}
                        >
                            <div
                                className={`menu-button-circular-inner-second`}
                            >
                                <div
                                    className={`menu-button-circular-inner-third`}
                                >
                                    <NavLink
                                        className={`button-text`}
                                        activeClassName="button-text-active"
                                        to={link}
                                    >
                                        {name}
                                    </NavLink>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Link>
        );
    } else {
        return (
            <Link className="link" to={link}>
                <div
                    className="menu-mobile-toggler"
                    onClick={() => {
                        toggler();
                    }}
                >
                    <div className="menu-button-regular">
                        <div className="menu-button-regular-inner">
                            <NavLink
                                className={`button-text`}
                                activeClassName="button-text-active"
                                to={link}
                            >
                                {name}
                            </NavLink>
                        </div>
                    </div>
                </div>
                <div className="menu-notmobile">
                    <div
                        className={`menu-button-regular ${status}`}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                    >
                        <div className="menu-button-regular-inner">
                            <NavLink
                                className={`button-text`}
                                activeClassName="button-text-active"
                                to={link}
                            >
                                {name}
                            </NavLink>
                        </div>
                    </div>
                </div>
            </Link>
        );
    }
}

export default MenuItem;
