import { useState } from "react";
import "./Social.css";
import SocialExtended from "./SocialExtended.js";

function Social() {
    const [extended, setExtended] = useState("");
    const [isExtended, setIsExtended] = useState(false);

    const handleEnter = () => {
        setExtended("social-extended");
        setTimeout(() => {
            setIsExtended(true);
        }, 300);
    };
    const handleLeave = () => {
        setExtended("");
        setTimeout(() => {
            setIsExtended(false);
        }, 300);
    };
    return (
        <div
            className={`social-container ${extended}`}
            onMouseEnter={handleEnter}
            onMouseLeave={handleLeave}
        >
            {!isExtended && (
                <div className={`social`}>
                    <i className="fas fa-link fa-link-social"></i>
                </div>
            )}
            {isExtended && <SocialExtended />}
        </div>
    );
}

export default Social;
