import "./Label.css";
import ArtistLinks from "../Reusable/ArtistLinks";
import { useState } from "react";
import { Transition } from "react-transition-group";
import MultiPlayer from "./Player";
import Carousel from "./Carousel";

function Label({ setBackgroundPosition }) {
    const [transition, setTransition] = useState();
    const [isLabelPicture, setIsLabelPicture] = useState(false);
    const images = ["./Front.jpg", "./RecordOut.jpg", "./Back.jpg"];
    const [currentImage, setCurrentImage] = useState(images[0]);

    const handleClick = () => {
        setIsLabelPicture(false);
    };

    const handleClickTrue = () => {
        if (window.innerWidth > 600) {
            setIsLabelPicture(true);
        }
    };

    setBackgroundPosition("four");

    setTimeout(() => {
        setTransition("label-entered");
    }, 800);

    return (
        <>
            <div className="label-maxi-container">
                <div className={`label ${transition}`}>
                    <div className="label-left">
                        <div className="label-left-first">
                            <div className="label-image-div">
                                <img
                                    className="label-image"
                                    src={currentImage}
                                    alt="Artist"
                                    onClick={handleClickTrue}
                                ></img>
                            </div>

                            <div className="label-dots">
                                {images.map((image, id) => (
                                    <>
                                        {currentImage === image && (
                                            <div className="label-dot-active"></div>
                                        )}
                                        {currentImage !== image && (
                                            <div
                                                className="label-dot"
                                                onClick={() =>
                                                    setCurrentImage(image)
                                                }
                                            ></div>
                                        )}
                                    </>
                                ))}
                            </div>
                        </div>
                        <div className="left-second">
                            <ArtistLinks
                                bc="https://kickshugs.bandcamp.com/"
                                ap="https://music.apple.com/it/album/vibe-before-hype-ep/1619154426"
                                sp="https://open.spotify.com/album/7l2rG9zYSaWEnsk6mA9oWR?si=S1wqCUOaRE--B2QJYGPgng&utm_source=copy-link"
                            />
                        </div>
                    </div>
                    <div className="right">
                        <div className="right-first">
                            <h1 className="label-name">
                                KH01 - V/A - VIBE BEFORE HYPE
                            </h1>
                            <p className="label-text">
                                Completely ecstatic & exhilarating maze of
                                rhythm by The Lone Flanger, additionally
                                reworked with Varg²™ versus contrasting yet
                                innovative dancefloor mechanics by Bertrand.,
                                ending with a hypnotic mix by Dasha Rush, the
                                record is an absorbing material of dynamics that
                                subtly surprise and leave nothing but an
                                ambitious statement for what’s yet to come.
                            </p>
                            <p className="label-text">
                                KH01 is dedicated to a musical shape-shifter, a
                                paramount figure, ephemeral talent & a dear
                                friend - Andrew Smith. Keep It Fungki!
                            </p>
                            <MultiPlayer
                                urls={[
                                    "./sn1.mp3",
                                    "./sn2.mp3",
                                    "./b2.mp3",
                                    "./b1.mp3",
                                ]}
                            />

                            <p>
                                <strong>
                                    “LIMITED 12” 180g Coloured Marbled + Instant
                                    WAV”
                                </strong>
                            </p>
                            <a
                                className="button-text"
                                href="https://kickshugs.bandcamp.com/releases"
                                rel="noreferrer"
                                target="_blank"
                            >
                                <div className="menu-button-regular">
                                    <div className="menu-button-regular-inner button-yellow">
                                        Buy Now
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
                <Transition in={isLabelPicture} timeout={600}>
                    {(state) => (
                        <div
                            className={`talent-picture-container fade fade-${state}`}
                        >
                            <div className={`label-picture-image-div`}>
                                <img
                                    src={currentImage}
                                    alt="carousel"
                                    className="label-picture-image"
                                    onClick={handleClick}
                                />
                                <i
                                    class="fas fa-times talent-picture-x"
                                    onClick={handleClick}
                                ></i>
                            </div>
                        </div>
                    )}
                </Transition>
            </div>

            {/* For mobile: */}
            <div className={`label-container`}>
                <h1 className="label-name">KH01 - V/A - VIBE BEFORE HYPE</h1>
                <Carousel>
                    <img src="./Front.jpg" alt="placeholder" />
                    <img src="./RecordOut.jpg" alt="placeholder" />
                    <img src="./Back.jpg" alt="placeholder" />
                </Carousel>
                {/* <div className="label-left-first">
                    <div className="label-image-div">
                        <img
                            className="label-image"
                            src={currentImage}
                            alt="Artist"
                            onClick={handleClickTrue}
                        ></img>
                    </div>

                    <div className="label-dots">
                        {images.map((image, id) => (
                            <>
                                {currentImage === image && (
                                    <div className="label-dot-active"></div>
                                )}
                                {currentImage !== image && (
                                    <div
                                        className="label-dot"
                                        onClick={() => setCurrentImage(image)}
                                    ></div>
                                )}
                            </>
                        ))}
                    </div>
                </div> */}
                <p className="label-text">
                    Completely ecstatic & exhilarating maze of rhythm by The
                    Lone Flanger, additionally reworked with Varg²™ versus
                    contrasting yet innovative dancefloor mechanics by
                    Bertrand., ending with a hypnotic mix by Dasha Rush, the
                    record is an absorbing material of dynamics that subtly
                    surprise and leave nothing but an ambitious statement for
                    what’s yet to come.
                </p>
                <p className="label-text">
                    KH01 is dedicated to a musical shape-shifter, a paramount
                    figure, ephemeral talent & a dear friend - Andrew Smith.
                    Keep It Fungki!
                </p>
                <MultiPlayer
                    urls={["./sn1.mp3", "./sn2.mp3", "./b2.mp3", "./b1.mp3"]}
                />

                <p>
                    <strong>
                        “LIMITED 12” 180g Coloured Marbled + Instant WAV”
                    </strong>
                </p>
                <a
                    className="button-text"
                    href="https://kickshugs.bandcamp.com/releases"
                    rel="noreferrer"
                    target="_blank"
                >
                    <button className="label-button button-yellow">
                        BUY NOW
                    </button>
                </a>
                <div className="label-links">
                    <ArtistLinks
                        bc="https://kickshugs.bandcamp.com/releases"
                        ap="/"
                        sp="/"
                    />
                </div>
            </div>
        </>
    );
}

export default Label;
