import { useEffect, useState } from "react";
import "./Admin.css";
import axios from "axios";
import Panel from "./Panel.js";

function Admin({ setBackgroundPosition }) {
    const [state, setState] = useState({});
    const [superuser, setSuperuser] = useState(false);

    useEffect(() => {
        setBackgroundPosition("three");
    }, []);

    useEffect(() => {
        (async () => {
            const { data } = await axios("/api/superuser");
            console.log("Status of cookie: ", data.status);
            if (data.status) {
                setSuperuser(true);
            }
        })();
    }, []);

    const logout = async () => {
        setSuperuser(false);
        const response = await axios("/api/logout");
        console.log(response);
    };

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
            error: false,
        });
    };

    const handleClick = async (e) => {
        e.preventDefault();
        if (!state.login || !state.password) {
            setState({ ...state, error: "Please fill in all the data" });
            console.log(state);
        } else {
            console.log("about to post the data");
            const { data } = await axios.post("/api/superuser/login", {
                login: state.login,
                password: state.password,
            });

            if (data.success) {
                console.log("autentication OK");
                setSuperuser(true);
            } else {
                setState({ error: "Something went wrong" });
                console.log("autentication notOK");
            }
        }
    };

    return (
        <>
            {!superuser && (
                <div id="superuser-container">
                    <h1 id="superuser-welcome">
                        Welcome to Kicks&Hugs Content Manager:
                    </h1>
                    <form id="superuser-form">
                        <div className="superuser-form-part">
                            <div className="superuser-label-div">
                                <label
                                    htmlFor="login"
                                    className="superuser-form-label"
                                >
                                    Login:
                                </label>
                            </div>
                            <div className="superuser-input-div">
                                <input
                                    className="superuser-form-input"
                                    name="login"
                                    onChange={handleChange}
                                />
                            </div>
                        </div>
                        <div className="superuser-form-part">
                            <div className="superuser-label-div">
                                <label
                                    htmlFor="password"
                                    className="superuser-form-label"
                                >
                                    Password:
                                </label>
                            </div>
                            <div className="superuser-input-div">
                                <input
                                    className="superuser-form-input"
                                    name="password"
                                    onChange={handleChange}
                                    type="password"
                                />
                            </div>
                        </div>

                        <div className="superuser-submit">
                            <button
                                className="superuser-submit-button"
                                onClick={handleClick}
                            >
                                Submit
                            </button>
                        </div>
                    </form>
                </div>
            )}
            {superuser && <Panel logout={logout} />}
        </>
    );
}

export default Admin;
