import { useEffect, useState, useRef } from "react";
import "./EditFeed.css";
import axios from "axios";

function EditFeed({ feed, handleClickPanel }) {
    useEffect(() => {
        setState({ ...state, oldPicture: feed.image });
    }, []);
    const [state, setState] = useState(feed);

    const fileInput = useRef(null);

    const fileButtonClick = (e) => {
        e.preventDefault();
        fileInput.current.click();
    };

    const handleImage = (e) => {
        setState({ ...state, image: e.target.files[0] });
        console.log(state);
    };

    const handleChange = (e) => {
        console.log(state);
        setState({
            ...state,
            [e.target.name]: e.target.value,
            error: false,
        });
    };

    const handleClick = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append("file", state.image);
        formData.append("title", state.title);
        formData.append("url", state.url);
        formData.append("tags", state.tags);
        formData.append("text", state.text);
        formData.append("sc", state.sc);
        formData.append("bc", state.bc);
        formData.append("ra", state.ra);
        formData.append("ig", state.ig);
        formData.append("sp", state.sp);
        formData.append("fb", state.fb);
        formData.append("yt", state.yt);
        formData.append("dc", state.dc);
        formData.append("ourl", state.ourl);
        formData.append("oldPicture", state.oldPicture);
        formData.append("id", state.id);

        const { data } = await axios.post("/api/superuser/editfeed", formData);

        handleClickPanel("Panel");
    };

    return (
        <div className="new-feed-div">
            <p className="new-feed-par">Edit Post</p>

            <form className="new-feed-form">
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="title" className="new-feed-label">
                            Title:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.title}
                            name="title"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>

                <div className="new-feed-form-section" id="invisible">
                    <div className="new-feed-label-div">
                        <label htmlFor="url" className="new-feed-label">
                            Url:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.url}
                            name="url"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="image" className="new-feed-label">
                            Image:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            ref={fileInput}
                            name="image"
                            onChange={handleImage}
                            type="file"
                            className="new-feed-input new-feed-file"
                        />
                        <div className="new-feed-file-button-div">
                            <button
                                className="new-feed-upload-btn"
                                onClick={fileButtonClick}
                            >
                                File
                            </button>
                            {state.image && (
                                <p id="file-link-name">{state.image.name}</p>
                            )}
                        </div>
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="tags" className="new-feed-label">
                            Tags:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.tags}
                            name="tags"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="text" className="new-feed-label">
                            Text:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <textarea
                            value={state.text}
                            name="text"
                            onChange={handleChange}
                            className="new-feed-input textarea"
                        />
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="sc" className="new-feed-label">
                            Soundcloud:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.sc}
                            name="sc"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="bc" className="new-feed-label">
                            Bandcamp:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.bc}
                            name="bc"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="dc" className="new-feed-label">
                            Discogs:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.dc}
                            name="dc"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="ra" className="new-feed-label">
                            Resident Advisor:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.ra}
                            name="ra"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="ig" className="new-feed-label">
                            Instagram:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.ig}
                            name="ig"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="sp" className="new-feed-label">
                            Spotify:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.sp}
                            name="sp"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="fb" className="new-feed-label">
                            Facebook:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.fb}
                            name="fb"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="yt" className="new-feed-label">
                            Youtube:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.yt}
                            name="yt"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>
                <div className="new-feed-form-section">
                    <div className="new-feed-label-div">
                        <label htmlFor="ourl" className="new-feed-label">
                            Other URL:
                        </label>
                    </div>
                    <div className="new-feed-input-div">
                        <input
                            value={state.ourl}
                            name="ourl"
                            onChange={handleChange}
                            className="new-feed-input"
                        />
                    </div>
                </div>

                <div className="new-feed-submit">
                    <button
                        className="new-feed-submit-button"
                        onClick={handleClick}
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    );
}

export default EditFeed;
