import { useState } from "react";
import "./SingleFeed.css";
import axios from "axios";
import ChangePosition from "./ChangePosition.js";

function SingleFeed({
    id,
    title,
    url,
    image,
    tags,
    text,
    sc,
    bc,
    ra,
    ig,
    sp,
    fb,
    yt,
    dc,
    ourl,
    timestamp,
    position,
    min,
    max,
    refresh,
    setIsEdit,
    handleClickPanel,
    setFeed,
}) {
    const deleteFeed = async (id) => {
        const { data } = await axios.post("/api/superuser/feed/:id", {
            id: id,
        });
        handleClickPanel("Panel");
    };

    return (
        <div className="single-feed-container">
            <div className="single-feed-image-div">
                <img className="single-feed-image" src={image}></img>
            </div>
            <div className="single-feed-name-div">
                <p className="single-feed-name-p">{title}</p>
                <p className="single-feed-name-p single-feed-name-p-date">
                    Posted at: {timestamp.substring(0, 10)}
                </p>
            </div>
            <div className="single-feed-icon-set">
                <div
                    className="single-feed-icon-div"
                    onClick={() => {
                        // handleClickPanel("EditFeed");
                        setIsEdit({
                            id: id,
                            title: title,
                            url: url,
                            image: image,
                            tags: tags,
                            text: text,
                            sc: sc,
                            bc: bc,
                            ra: ra,
                            ig: ig,
                            sp: sp,
                            fb: fb,
                            yt: yt,
                            dc: dc,
                            ourl: ourl,
                        });
                    }}
                >
                    <i className="far fa-edit single-feed-icon"></i>
                </div>
                <div
                    className="single-feed-icon-div"
                    onClick={() => deleteFeed(id)}
                >
                    <i className="far fa-trash-alt single-feed-icon"></i>
                </div>
                <ChangePosition
                    position={position}
                    min={min}
                    max={max}
                    refresh={refresh}
                />
            </div>
        </div>
    );
}

export default SingleFeed;
