import "./BookNow.css";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import {
    CountryDropdown,
    RegionDropdown,
    CountryRegionData,
} from "react-country-region-selector";
import EmailConfirmation from "../Reusable/EmailConfirmation.js";

function BookNow({ contact, setContact, setBackgroundPosition }) {
    const [transition, setTransition] = useState();
    const form = useRef(null);
    const scroll = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setTransition("booknow-container-entered");
        }, 800);
    }, []);

    useEffect(() => {
        scroll.current.scrollIntoView();
    }, []);

    useEffect(() => {
        if (contact) {
            setState({ ...state, artist: contact });
        }
    }, []);

    const [state, setState] = useState({
        artist: "",
        country: "",
        region: "",
        city: "",
        date: "",
        first: "",
        last: "",
        email: "",
        venuename: "",
        capacity: "",
        time: "",
        price: "",
        offer: "",
        promoter: "",
        event: "",
        open: "",
        closed: "",
    });
    const [sent, setSent] = useState(false);
    const [artistsNames, setArtistsNames] = useState();

    useEffect(() => {
        setBackgroundPosition("three");
    }, []);

    // Country and region API setup:

    const { country, region } = state;

    const selectCountry = (val) => {
        setState({
            ...state,
            country: val,
        });
    };

    const selectRegion = (val) => {
        setState({
            ...state,
            region: val,
        });
    };

    useEffect(() => {
        (async () => {
            const { data } = await axios("/api/artistsnames");
            console.log(data.rows);
            setArtistsNames(data.rows);
        })();
    }, []);

    const handleChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value,
        });
    };

    const handleClick = async (e) => {
        e.preventDefault();
        const isValid = form.current.reportValidity();

        if (isValid) {
            const { data } = await axios.post("/api/booknow", state);
            setSent(true);
            setState({
                artist: "",
                country: "",
                region: "",
                city: "",
                date: "",
                first: "",
                last: "",
                email: "",
                venuename: "",
                capacity: "",
                time: "",
                price: "",
                offer: "",
                promoter: "",
                event: "",
                open: "",
                closed: "",
            });
            setContact(false);
        }
    };

    return (
        <div className="booknow-background">
            <div ref={scroll}>
                <form ref={form}>
                    <div className={`booknow-container ${transition}`}>
                        <div className="booknow-form-div">
                            <div className="booknow-item">
                                <div className="booknow-section">
                                    <h1 className="booknow-headline">
                                        Required information*
                                    </h1>

                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="artist"
                                        >
                                            *Artist:
                                        </label>
                                        <select
                                            onChange={handleChange}
                                            className="booknow-select"
                                            name="artist"
                                            value={state.artist}
                                            required
                                        >
                                            <option
                                                selected
                                                disabled={true}
                                                value=""
                                            >
                                                Select an artist...
                                            </option>

                                            {artistsNames &&
                                                artistsNames.map((each) => (
                                                    <>
                                                        {each.url !==
                                                            "jasen-loveland" &&
                                                            each.url !==
                                                                "the-lone-flanger" && (
                                                                <option
                                                                    value={
                                                                        each.name
                                                                    }
                                                                >
                                                                    {each.name}
                                                                </option>
                                                            )}
                                                    </>
                                                ))}
                                        </select>
                                    </div>

                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="country"
                                        >
                                            *Country:
                                        </label>
                                        <CountryDropdown
                                            className="booknow-input-country"
                                            value={country}
                                            onChange={(val) =>
                                                selectCountry(val)
                                            }
                                            required
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="country"
                                        >
                                            *Region:
                                        </label>
                                        <RegionDropdown
                                            className="booknow-input-country"
                                            country={country}
                                            value={region}
                                            onChange={(val) =>
                                                selectRegion(val)
                                            }
                                            required
                                        />
                                    </div>

                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="city"
                                        >
                                            *City:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input"
                                            name="city"
                                            value={state.city}
                                            required
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="date"
                                        >
                                            *Date:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input-date"
                                            name="date"
                                            type="date"
                                            value={state.date}
                                            required
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="first"
                                        >
                                            *First Name:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input"
                                            name="first"
                                            value={state.first}
                                            required
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="last"
                                        >
                                            *Last Name:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input"
                                            name="last"
                                            value={state.last}
                                            required
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="email"
                                        >
                                            *Email:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input"
                                            name="email"
                                            value={state.email}
                                            required
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="booknow-item">
                                <div className="booknow-section">
                                    <h1 className="booknow-headline">
                                        Additional information
                                    </h1>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="venuename"
                                        >
                                            Venue Name:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input"
                                            name="venuename"
                                            value={state.venuename}
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="capacity"
                                        >
                                            Capacity:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input"
                                            name="capacity"
                                            value={state.capacity}
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="time"
                                        >
                                            Set Time:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input-date"
                                            name="time"
                                            type="time"
                                            value={state.time}
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="price"
                                        >
                                            Entry Price:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input"
                                            name="price"
                                            placeholder="Include currency"
                                            value={state.price}
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="offer"
                                        >
                                            Your Offer:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input"
                                            name="offer"
                                            placeholder="Include currency"
                                            value={state.offer}
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="promoter"
                                        >
                                            Promoter:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input"
                                            name="promoter"
                                            value={state.promoter}
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="event"
                                        >
                                            Event Name:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input"
                                            name="event"
                                            value={state.event}
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="open"
                                        >
                                            Doors Open:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input-date"
                                            name="open"
                                            type="time"
                                            value={state.open}
                                        />
                                    </div>
                                    <div className="booknow-line">
                                        <label
                                            className="booknow-label"
                                            htmlFor="closed"
                                        >
                                            Doors Closed:
                                        </label>
                                        <input
                                            onChange={handleChange}
                                            className="booknow-input-date"
                                            name="closed"
                                            type="time"
                                            value={state.closed}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div id="booknow-send-outter">
                                <input
                                    onClick={handleClick}
                                    id="booknow-send-inner"
                                    type="submit"
                                    value="Send"
                                />
                            </div>
                        </div>
                        <p></p>
                    </div>
                </form>
            </div>
            {sent && <EmailConfirmation handleClick={() => setSent(false)} />}
        </div>
    );
}

export default BookNow;
