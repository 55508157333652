import React, { useState, useEffect } from "react";
import "./Player.css";

const useMultiAudio = (urls) => {
    const trackNames = [
        "Varg²™, The Lone Flanger - Fuzzy-Trace Theory",
        "The Lone Flanger - HD 140283 (The Cheetah Song)",
        "Bertrand. - Spray (Original Mix)",
        "Bertrand. - Spray (Dasha Rush Hypnotic Mix)",
    ];

    const [sources] = useState(
        urls.map((url, i) => {
            return {
                url,
                audio: new Audio(url),
            };
        })
    );

    const [players, setPlayers] = useState(
        urls.map((url, i) => {
            return {
                url,
                playing: false,
                trackName: trackNames[i],
            };
        })
    );

    const toggle = (targetIndex) => () => {
        const newPlayers = [...players];
        const currentIndex = players.findIndex((p) => p.playing === true);
        if (currentIndex !== -1 && currentIndex !== targetIndex) {
            newPlayers[currentIndex].playing = false;
            newPlayers[targetIndex].playing = true;
        } else if (currentIndex !== -1) {
            newPlayers[targetIndex].playing = false;
        } else {
            newPlayers[targetIndex].playing = true;
        }
        setPlayers(newPlayers);
    };

    useEffect(() => {
        sources.forEach((source, i) => {
            players[i].playing ? source.audio.play() : source.audio.pause();
        });
    }, [sources, players]);

    (function () {
        sources.forEach((source, i) => {
            source.audio.addEventListener("ended", () => {
                const newPlayers = [...players];
                newPlayers[i].playing = false;
                setPlayers(newPlayers);
            });
        });
        return () => {
            sources.forEach((source, i) => {
                source.audio.removeEventListener("ended", () => {
                    const newPlayers = [...players];
                    newPlayers[i].playing = false;
                    setPlayers(newPlayers);
                });
            });
        };
    })();

    return [players, toggle];
};

const MultiPlayer = ({ urls }) => {
    const [players, toggle] = useMultiAudio(urls);
    return (
        <div>
            {players.map((player, i) => (
                <Player key={i} player={player} toggle={toggle(i)} />
            ))}
        </div>
    );
};

const Player = ({ player, toggle }) => (
    <div onClick={toggle}>
        {!player.playing && (
            <>
                <i className="fa-solid fa-circle-play"></i>
                <span> {player.trackName}</span>
            </>
        )}
        {player.playing && (
            <>
                <i className="fa-solid fa-circle-pause"></i>
                <span className="track-playing">{player.trackName}</span>
                <i class="fa-solid fa-volume-high"></i>
            </>
        )}
    </div>
);

export default MultiPlayer;
