import "./Carousel.css";
import { useState, useEffect } from "react";

const Carousel = (props) => {
    const { children } = props;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [length, setLength] = useState(children.length);

    // Set the length to match current children from props
    useEffect(() => {
        setLength(children.length);
    }, [children]);
    // ...

    const next = () => {
        if (currentIndex < length - 1) {
            setCurrentIndex((prevState) => prevState + 1);
        }
    };

    const prev = () => {
        if (currentIndex > 0) {
            setCurrentIndex((prevState) => prevState - 1);
        }
    };

    const [touchPosition, setTouchPosition] = useState(null);
    const handleTouchStart = (e) => {
        const touchDown = e.touches[0].clientX;
        setTouchPosition(touchDown);
    };

    const handleTouchMove = (e) => {
        const touchDown = touchPosition;

        if (touchDown === null) {
            return;
        }

        const currentTouch = e.touches[0].clientX;
        const diff = touchDown - currentTouch;

        if (diff > 5) {
            next();
        }

        if (diff < -5) {
            prev();
        }

        setTouchPosition(null);
    };

    return (
        <>
            <div className="carousel-container">
                <div className="carousel-wrapper">
                    <div
                        className="carousel-content-wrapper"
                        onTouchStart={handleTouchStart}
                        onTouchMove={handleTouchMove}
                    >
                        <div
                            className="carousel-content"
                            style={{
                                transform: `translateX(-${
                                    currentIndex * 100
                                }%)`,
                            }}
                        >
                            {children}
                        </div>
                    </div>
                    {currentIndex > 0 && (
                        <button onClick={prev} className="left-arrow">
                            &lt;
                        </button>
                    )}

                    {currentIndex < length - 1 && (
                        <button onClick={next} className="right-arrow">
                            &gt;
                        </button>
                    )}
                </div>
            </div>
        </>
    );
};

export default Carousel;
