import "./TalentsPicture.css";

function TalentsPicture({ url, name }) {
    return (
        <>
            <div className="talents-picture-container">
                <img src={url} className="talents-picture-image" />
            </div>
            <h1 className="talents-picture-name">{name}</h1>
        </>
    );
}

export default TalentsPicture;
