import "./FormLine.css";
import { useRef } from "react";

function FormLine({ name, label, handleChange, fieldValue }) {
    return (
        <div className="contact-line">
            <label className="contact-label" htmlFor={name}>
                {label}
            </label>
            <input
                className="contact-input"
                name={name}
                onChange={(e) => handleChange(e)}
                value={fieldValue}
                required
            />
        </div>
    );
}

export default FormLine;
