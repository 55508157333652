import { useEffect } from "react";
import "./ArtistLinks.css";

function ArtistLinks({ sc, bc, ra, ig, sp, fb, yt, dc, pdf, ourl, ap }) {
    return (
        <>
            {sc && (
                <a className="a" href={sc} rel="noreferrer" target="_blank">
                    <div className="artist-link orange">
                        <i class="artist-icon fab fa-soundcloud"></i>
                    </div>
                </a>
            )}
            {bc && (
                <a className="a" href={bc} rel="noreferrer" target="_blank">
                    <div className="artist-link cadetblue">
                        <i class="artist-icon fab fa-bandcamp"></i>
                    </div>
                </a>
            )}
            {ap && (
                <a className="a" href={ap} rel="noreferrer" target="_blank">
                    <div className="artist-link apple">
                        <img
                            src="/apple-music.svg"
                            class="artist-icon ap"
                        ></img>
                    </div>
                </a>
            )}
            {ra && (
                <a className="a" href={ra} rel="noreferrer" target="_blank">
                    <div className="artist-link chartreuse">
                        {/* <i class="artist-icon fas fa-anchor"></i> */}
                        <img src="/293119.svg" class="artist-icon ra"></img>
                    </div>
                </a>
            )}
            {ig && (
                <a className="a" href={ig} rel="noreferrer" target="_blank">
                    <div className="artist-link pink">
                        <i class="artist-icon fab fa-instagram"></i>
                    </div>
                </a>
            )}
            {sp && (
                <a className="a" href={sp} rel="noreferrer" target="_blank">
                    <div className="artist-link color">
                        <i class="artist-icon fab fa-spotify"></i>
                    </div>
                </a>
            )}
            {fb && (
                <a className="a" href={fb} rel="noreferrer" target="_blank">
                    <div className="artist-link blue">
                        <i class="artist-icon-facebook fab fa-facebook-f artist-icon"></i>
                    </div>
                </a>
            )}
            {yt && (
                <a className="a" href={yt} rel="noreferrer" target="_blank">
                    <div className="artist-link red">
                        <i class="artist-icon fab fa-youtube"></i>
                    </div>
                </a>
            )}
            {dc && (
                <a className="a" href={dc} rel="noreferrer" target="_blank">
                    <div className="artist-link lime">
                        <img
                            src="/discogs vinyl record mark.svg"
                            class="artist-icon dc"
                        ></img>
                    </div>
                </a>
            )}
            {pdf && (
                <a className="a" href={`${pdf}`} target="_blank">
                    <div className="artist-link grey">
                        <i class="artist-icon far fa-file-pdf"></i>
                    </div>
                </a>
            )}
            {ourl && (
                <a className="a" href={ourl} rel="noreferrer" target="_blank">
                    <div className="artist-link purple">
                        <i className="artist-icon fas fa-link"></i>
                    </div>
                </a>
            )}
        </>
    );
}

export default ArtistLinks;
