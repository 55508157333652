import "./Logo.css";
import { Link } from "react-router-dom";

function Logo({ src }) {
    return (
        <Link to="/feed" className="logo-link">
            <div className="logo-div">
                <img src={`/${src}`} alt="Logo Neon" id="logo-image" />
            </div>
        </Link>
    );
}

export default Logo;
