import "./ChangePosition.css";
import axios from "axios";

function ChangePosition({ position, min, max, refresh }) {
    console.log("max and min: ", min, max);
    const handleClick = async (arg) => {
        const { data } = await axios.post("/api/superuser/changePosition", {
            position: position,
            direction: arg,
        });
        refresh(data.id);
    };
    return (
        <div className="single-feed-arrows">
            {position !== max && (
                <i
                    className="fas fa-caret-up single-feed-icon"
                    onClick={() => handleClick(">")}
                ></i>
            )}
            {position !== min && (
                <i
                    className="fas fa-caret-down single-feed-icon"
                    onClick={() => handleClick("<")}
                ></i>
            )}
        </div>
    );
}

export default ChangePosition;
