import "./FeedMini.css";
import { Link } from "react-router-dom";
import ArtistLinks from "../Reusable/ArtistLinks.js";
import { useEffect, useState } from "react";

function Feed({ data, setIsPicture, setIsIn }) {
    const [description, setDescription] = useState(false);
    const [readMore, setReadMore] = useState(true);

    useEffect(() => {
        if (data.text.length > 1100) {
            setReadMore(true);
        } else {
            setReadMore(false);
        }
    }, []);

    const handleClick = (arg) => {
        setIsPicture(arg);
        setIsIn(true);
    };

    // Desktop version, displaying news feed without text:

    if (!data.text) {
        return (
            <div
                className="feed-item"
                onMouseEnter={() => {
                    setDescription(true);
                }}
                onMouseLeave={() => {
                    setDescription(false);
                }}
            >
                <div className="feed-image-div">
                    <img
                        src={data.image}
                        alt={data.title}
                        className="feed-image"
                        onClick={() => handleClick(data.image)}
                    />

                    {description && (
                        <>
                            <div className="feed-title">
                                <h1 className="feed-title-h">{data.title}</h1>
                            </div>

                            <div className="feed-social">
                                <ArtistLinks sc={data.sc} />
                                <ArtistLinks bc={data.bc} />
                                <ArtistLinks dc={data.dc} />
                                <ArtistLinks sp={data.sp} />
                                <ArtistLinks ra={data.ra} />
                                <ArtistLinks ig={data.ig} />
                                <ArtistLinks fb={data.fb} />
                                <ArtistLinks yt={data.yt} />
                                <ArtistLinks ourl={data.ourl} />
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
        // Displaying news feed with text:
    } else {
        return (
            <div className="feed-item-with-text">
                <h1 className="feed-title-with-text">{data.title}</h1>
                <div className="feed-item-with-text-container">
                    <div className="feed-item-content">
                        {readMore && (
                            <div className="feed-text-with-text">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            data.text.substring(0, 1100) +
                                            "...",
                                    }}
                                ></div>
                                <p
                                    className="read-more"
                                    onClick={() => setReadMore(false)}
                                >
                                    Read More >>
                                </p>
                            </div>
                        )}
                        {!readMore && (
                            <div className="feed-text-with-text">
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: data.text,
                                    }}
                                ></div>
                                {data.text.length > 1100 && (
                                    <span
                                        className="read-more"
                                        onClick={() => setReadMore(true)}
                                    >
                                        Hide
                                    </span>
                                )}
                            </div>
                        )}
                        <div className="feed-social-with-text">
                            <ArtistLinks
                                sc={data.sc}
                                bc={data.bc}
                                dc={data.dc}
                                sp={data.sp}
                                ra={data.ra}
                                ig={data.ig}
                                fb={data.fb}
                                yt={data.yt}
                                ourl={data.ourl}
                            />
                        </div>
                    </div>
                    <div className="feed-item-with-text-photo-container">
                        <img
                            src={data.image}
                            alt={data.title}
                            className="feed-item-with-text-photo"
                            onClick={() => handleClick(data.image)}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Feed;
