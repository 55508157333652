import "./SocialExtended.css";

function SocialExtended({ changeSocial }) {
    return (
        <div className="social-extended-container" onMouseLeave={changeSocial}>
            <div className="social-extended-link cadetblue">
                <a
                    className="social-extended-a"
                    href="https://kickshugs.bandcamp.com/releases"
                    rel="noreferrer"
                    target="_blank"
                >
                    <i class="social-extended-icon fab fa-bandcamp"></i>
                </a>
            </div>
            <div className="social-extended-link apple">
                <a
                    className="social-extended-a"
                    href="https://music.apple.com/it/album/vibe-before-hype-ep/1619154426"
                    rel="noreferrer"
                    target="_blank"
                >
                    <img
                        src="/apple-music.svg"
                        class="social-extended-icon"
                    ></img>
                </a>
            </div>
            <div className="social-extended-link green">
                <a
                    className="social-extended-a"
                    href="https://open.spotify.com/album/7l2rG9zYSaWEnsk6mA9oWR?si=S1wqCUOaRE--B2QJYGPgng&utm_source=copy-link"
                    rel="noreferrer"
                    target="_blank"
                >
                    <i class="social-extended-icon fab fa-spotify"></i>
                </a>
            </div>
            <div className="social-extended-link light-blue">
                <a
                    className="social-extended-a"
                    href="https://twitter.com/KicksHugs"
                    rel="noreferrer"
                    target="_blank"
                >
                    <img src="/twitter.svg" class="social-extended-icon"></img>
                </a>
            </div>
            <div className="social-extended-link pink">
                <a
                    className="social-extended-a"
                    href="https://www.instagram.com/kickshugs/"
                    rel="noreferrer"
                    target="_blank"
                >
                    <i class="social-extended-icon fab fa-instagram"></i>
                </a>
            </div>
            <div className="social-extended-link blue">
                <a
                    className="social-extended-a"
                    href="https://www.facebook.com/Kickshugs/"
                    rel="noreferrer"
                    target="_blank"
                >
                    {/* <i class="social-extended-icon fab fa-facebook"></i> */}
                    <i class="artist-icon-facebook social-extended-icon fab fa-facebook-f"></i>
                </a>
            </div>
        </div>
    );
}

export default SocialExtended;
