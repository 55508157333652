import "./Feed.css";
import axios from "axios";
import { useEffect, useState, useRef } from "react";
import FeedMini from "./FeedMini.js";
import FeedMiniMobile from "./FeedMiniMobile.js";
import { Transition } from "react-transition-group";

function Feed({ setBackgroundPosition }) {
    const [feedData, setFeedData] = useState([]);
    const [minId, setMinId] = useState();
    const [minIdInDatabase, setMinIdInDataBase] = useState();
    const [isPicture, setIsPicture] = useState(false);
    const [isIn, setIsIn] = useState(false);
    const [transition, setTransition] = useState();
    const scroll = useRef(null);

    useEffect(() => {
        setBackgroundPosition("two");
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setTransition("feed-container-entered");
        }, 800);
    }, []);

    useEffect(() => {
        (async () => {
            const { data } = await axios("/api/feedLoadMore");
            await setFeedData(data.rows);
            setMinId(data.rows[0].nextQuery);
        })();
    }, []);

    useEffect(() => {
        scroll.current.scrollIntoView(0, 0);
    }, []);

    const handleClick = async () => {
        const { data } = await axios(`/api/feedLoadMore/${minId}`);
        setFeedData([...feedData, ...data.rows]);
        setMinId(data.rows[0].nextQuery);
        setMinIdInDataBase(data.rows[0].lowestId);
    };

    return (
        <>
            <div className="feed-background">
                <div className={`feed-container ${transition}`} ref={scroll}>
                    {feedData &&
                        feedData.map((each) => (
                            <FeedMini
                                data={each}
                                key={each.id}
                                setIsPicture={setIsPicture}
                                setIsIn={setIsIn}
                            />
                        ))}
                    {feedData &&
                        feedData.map((each) => (
                            <FeedMiniMobile
                                data={each}
                                key={each.id}
                                minId={minId}
                            />
                        ))}
                    {minIdInDatabase !== minId && (
                        <button id="load-more-button" onClick={handleClick}>
                            Load More
                        </button>
                    )}
                    <p></p>
                </div>
                <Transition in={isIn} timeout={600}>
                    {(state) => (
                        <div
                            className={`feed-maxi-container fade fade-${state}`}
                        >
                            <div className={`feed-maxi-image-div`}>
                                <img
                                    src={isPicture}
                                    className="feed-maxi-image"
                                    onClick={() => {
                                        setIsIn(false);
                                    }}
                                />
                                <i
                                    class="fas fa-times feed-maxi-x"
                                    onClick={() => {
                                        setIsIn(false);
                                    }}
                                ></i>
                            </div>
                        </div>
                    )}
                </Transition>
            </div>
        </>
    );
}

export default Feed;
