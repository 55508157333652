import { useEffect, useState } from "react";
import "./Panel.css";
import Artists from "./Artists/Artists.js";
import Feed from "./Feed/Feed.js";
import NewArtist from "./Artists/NewArtist.js";
import EditArtist from "./Artists/EditArtist";
import NewFeed from "./Feed/NewFeed.js";
import EditFeed from "./Feed/EditFeed.js";

function Panel({ logout }) {
    const [mode, setMode] = useState("Panel");
    const [artist, setArtist] = useState({});
    const [feed, setFeed] = useState({});

    const handleClick = (arg) => {
        setMode(arg);
    };
    return (
        <div id="panel-container">
            <div id="panel-content">
                {mode === "Panel" && (
                    <div>
                        <div
                            className="panel-line"
                            onClick={() => handleClick("Feed")}
                        >
                            <h1 className="panel-label">Feed</h1>
                        </div>
                        <div
                            className="panel-line"
                            onClick={() => handleClick("Artists")}
                        >
                            <h1 className="panel-label">Talents</h1>
                        </div>
                    </div>
                )}
                {mode === "Artists" && (
                    <Artists
                        handleClickPanel={handleClick}
                        setArtist={setArtist}
                    />
                )}
                {mode === "Feed" && (
                    <Feed handleClickPanel={handleClick} setFeed={setFeed} />
                )}

                {mode === "EditArtist" && (
                    <EditArtist
                        handleClickPanel={handleClick}
                        artist={artist}
                    />
                )}
                {mode === "EditFeed" && (
                    <EditFeed handleClickPanel={handleClick} feed={feed} />
                )}

                {mode === "Artists" && (
                    <div
                        className="panel-back-button"
                        onClick={() => handleClick("Panel")}
                    >
                        <div className="back-button">
                            <i class="fas fa-chevron-circle-left"></i>
                            <span id="back">Back</span>
                        </div>
                    </div>
                )}
                {mode === "Feed" && (
                    <div
                        className="panel-back-button"
                        onClick={() => handleClick("Panel")}
                    >
                        <div className="back-button">
                            <i class="fas fa-chevron-circle-left"></i>
                            <span id="back">Back</span>
                        </div>
                    </div>
                )}
                {mode === "NewArtist" && (
                    <div
                        className="panel-back-button"
                        onClick={() => handleClick("Artists")}
                    >
                        <div className="back-button">
                            <i class="fas fa-chevron-circle-left"></i>
                            <span id="back">Back</span>
                        </div>
                    </div>
                )}
                {mode === "NewFeed" && (
                    <div
                        className="panel-back-button"
                        onClick={() => handleClick("Feed")}
                    >
                        <div className="back-button">
                            <i class="fas fa-chevron-circle-left"></i>
                            <span id="back">Back</span>
                        </div>
                    </div>
                )}
                {mode === "EditArtist" && (
                    <div
                        className="panel-back-button"
                        onClick={() => handleClick("Artists")}
                    >
                        <div className="back-button">
                            <i class="fas fa-chevron-circle-left"></i>
                            <span id="back">Back</span>
                        </div>
                    </div>
                )}
                {mode === "EditFeed" && (
                    <div
                        className="panel-back-button"
                        onClick={() => handleClick("Feed")}
                    >
                        <div className="back-button">
                            <i class="fas fa-chevron-circle-left"></i>
                            <span id="back">Back</span>
                        </div>
                    </div>
                )}

                <div className="panel-line" onClick={logout}>
                    <h1 className="panel-label">Logout</h1>
                </div>
            </div>
        </div>
    );
}

export default Panel;
