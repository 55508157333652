import { useState, useEffect } from "react";
import "./Artists.css";
import axios from "axios";
import SingleArtist from "./SingleArtist.js";
import NewArtist from "./NewArtist";
import EditArtist from "./EditArtist";

function Artists({ handleClickPanel, setArtist }) {
    const [artistsData, setArtistsData] = useState([]);
    const [isEdit, setIsEdit] = useState();

    useEffect(() => {
        (async () => {
            const { data } = await axios("/api/artists");
            setArtistsData(data.rows);
            console.log("data: ", data.rows);
        })();
    }, []);

    return (
        <>
            <div className="artists-admin-container">
                <div className="artists-admin-left">
                    <p className="artists-par">All artists</p>

                    {artistsData &&
                        artistsData.map((each) => (
                            <SingleArtist
                                key={each.id}
                                id={each.id}
                                name={each.name}
                                url={each.url}
                                image={each.image}
                                imagemini={each.imagemini}
                                role={each.role}
                                bio={each.bio}
                                sc={each.sc}
                                bc={each.bc}
                                ra={each.ra}
                                ig={each.ig}
                                sp={each.sp}
                                fb={each.fb}
                                yt={each.yt}
                                dc={each.dc}
                                pdf={each.pdf}
                                handleClickPanel={handleClickPanel}
                                setArtist={setArtist}
                                setIsEdit={setIsEdit}
                            />
                        ))}
                </div>
                <div className="artists-admin-right">
                    {!isEdit && (
                        <>
                            <p className="artists-par">New Artist</p>
                            <NewArtist handleClickPanel={handleClickPanel} />
                        </>
                    )}
                    {isEdit && (
                        <>
                            <p className="artists-par">Edit Artist</p>
                            <EditArtist
                                artist={isEdit}
                                key={isEdit.id}
                                handleClickPanel={handleClickPanel}
                            />
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default Artists;
