import "./MenuMobile.css";
import SocialExtended from "../Global/SocialExtended.js";
import Menu from "../Global/Menu.js";

function MenuMobile({ toggler, changeMode }) {
    return (
        <div className="mobile-div">
            <Menu toggler={toggler} changeMode={changeMode} />
            <SocialExtended />
        </div>
    );
}

export default MenuMobile;
