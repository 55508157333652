import { useState } from "react";
import "./SingleArtist.css";
import axios from "axios";

function SingleArtist({
    name,
    id,
    url,
    image,
    imagemini,
    role,
    bio,
    sc,
    bc,
    ra,
    ig,
    sp,
    fb,
    yt,
    dc,
    pdf,
    setIsEdit,
    handleClickPanel,
    setArtist,
}) {
    const deleteArtist = async (id) => {
        const { data } = await axios.post("/api/superuser/artist/:id", {
            id: id,
        });
        handleClickPanel("Panel");
    };

    return (
        <div className="single-artist-container">
            <div className="single-artist-image-div">
                <img className="single-artist-image" src={imagemini}></img>
            </div>
            <div className="single-artist-name-div">
                <p className="single-artist-name-p">{name}</p>
            </div>
            <div
                className="single-artist-icon-div"
                onClick={() => {
                    // handleClickPanel("EditArtist");
                    setIsEdit({
                        name: name,
                        id: id,
                        url: url,
                        image: image,
                        imagemini: imagemini,
                        role: role,
                        bio: bio,
                        sc: sc,
                        bc: bc,
                        ra: ra,
                        ig: ig,
                        sp: sp,
                        fb: fb,
                        yt: yt,
                        dc: dc,
                        pdf: pdf,
                    });
                }}
            >
                <i className="far fa-edit single-artist-icon"></i>
            </div>
            <div
                className="single-artist-icon-div"
                onClick={() => deleteArtist(id)}
            >
                <i className="far fa-trash-alt single-artist-icon"></i>
            </div>
        </div>
    );
}

export default SingleArtist;
