import "./ArtistMini.css";
import { Link } from "react-router-dom";

function ArtistMini({ name, imagemini, setContact, url }) {
    const handleContact = () => {
        if (url !== "jasen-loveland" && url !== "the-lone-flanger") {
            setContact(name);
        }
    };
    return (
        <div className="artist-mini" onClick={handleContact}>
            <p className="artist-mini-name">{name}</p>
            <img className="artist-mini-picture" src={imagemini} alt={name} />
        </div>
    );
}

export default ArtistMini;
