import { useEffect, useState, useRef } from "react";
import "./EditArtist.css";
import axios from "axios";
import FileEditor from "../FileEditor.js";

function EditArtist({ artist, handleClickPanel }) {
    useEffect(() => {
        setState({
            ...state,
            oldPicture: artist.image,
            oldPdf: artist.pdf,
            oldMini: artist.imagemini,
        });
    }, []);
    const [state, setState] = useState(artist);

    const fileInput = useRef(null);
    const fileInputPdf = useRef(null);
    const fileInputMini = useRef(null);

    const fileButtonClick = (e) => {
        e.preventDefault();
        fileInput.current.click();
    };
    const fileButtonClickPdf = (e) => {
        e.preventDefault();
        fileInputPdf.current.click();
    };
    const fileButtonClickMini = (e) => {
        e.preventDefault();
        fileInputMini.current.click();
    };

    const handleImage = (e) => {
        setState({ ...state, image: e.target.files[0] });
        console.log(state);
    };
    const handlePdf = (e) => {
        setState({ ...state, pdf: e.target.files[0] });
        console.log(state);
    };
    const handleImageMini = (e) => {
        setState({ ...state, imagemini: e.target.files[0] });
        console.log(state);
    };

    const handleChange = (e) => {
        console.log("Old Mini: ", artist.imagemini);
        console.log("Old IMAGE: ", artist.image);
        console.log("Old PDF: ", artist.pdf);
        setState({
            ...state,
            [e.target.name]: e.target.value,
            error: false,
        });
    };

    const handleClick = async (e) => {
        e.preventDefault();

        const formData = new FormData();
        formData.append(`image`, state.image);
        formData.append(`pdf`, state.pdf);
        formData.append(`imagemini`, state.imagemini);
        formData.append("name", state.name);
        formData.append("url", state.url);
        formData.append("role", state.role);
        formData.append("bio", state.bio);
        formData.append("sc", state.sc);
        formData.append("bc", state.bc);
        formData.append("ra", state.ra);
        formData.append("ig", state.ig);
        formData.append("sp", state.sp);
        formData.append("fb", state.fb);
        formData.append("yt", state.yt);
        formData.append("dc", state.dc);
        formData.append("oldPicture", state.oldPicture);
        formData.append("oldPdf", state.oldPdf);
        formData.append("oldMini", state.oldMini);
        formData.append("id", state.id);

        const { data } = await axios.post(
            "/api/superuser/editartist",
            formData
        );

        handleClickPanel("Panel");
    };

    return (
        <form className="new-artist-form">
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="name" className="new-artist-label">
                        Name:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        value={state.name}
                        name="name"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>

            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="url" className="new-artist-label">
                        Url:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        value={state.url}
                        name="url"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="image" className="new-artist-label">
                        Image:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        ref={fileInput}
                        name="image"
                        onChange={handleImage}
                        type="file"
                        className="new-artist-input new-artist-file"
                    />
                    <div className="new-artist-file-button-div">
                        <button
                            className="new-artist-upload-btn"
                            onClick={fileButtonClick}
                        >
                            File
                        </button>
                        {state.image && (
                            <p id="file-link-name">{state.image.name}</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="image" className="new-artist-label">
                        Miniature:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        ref={fileInputMini}
                        name="imagemini"
                        onChange={handleImageMini}
                        type="file"
                        className="new-artist-input new-artist-file"
                    />
                    <div className="new-artist-file-button-div">
                        <button
                            className="new-artist-upload-btn"
                            onClick={fileButtonClickMini}
                        >
                            File
                        </button>
                        {state.imagemini && (
                            <p id="file-link-name">{state.imagemini.name}</p>
                        )}
                    </div>
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="role" className="new-artist-label">
                        Role:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        value={state.role}
                        name="role"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="bio" className="new-artist-label">
                        Bio:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <textarea
                        value={state.bio}
                        name="bio"
                        onChange={handleChange}
                        className="new-artist-input textarea"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="sc" className="new-artist-label">
                        Soundcloud:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        value={state.sc}
                        name="sc"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="bc" className="new-artist-label">
                        BandCamp:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        value={state.bc}
                        name="bc"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="ra" className="new-artist-label">
                        Resident Advisor:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        value={state.ra}
                        name="ra"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="ia" className="new-artist-label">
                        Instagram:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        value={state.ig}
                        name="ig"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="sp" className="new-artist-label">
                        Spotify:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        value={state.sp}
                        name="sp"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="fb" className="new-artist-label">
                        Facebook:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        value={state.fb}
                        name="fb"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="yt" className="new-artist-label">
                        Youtube:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        value={state.yt}
                        name="yt"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>
            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="dc" className="new-artist-label">
                        Discogs:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        value={state.dc}
                        name="dc"
                        onChange={handleChange}
                        className="new-artist-input"
                    />
                </div>
            </div>

            <div className="new-artist-form-section">
                <div className="new-artist-label-div">
                    <label htmlFor="image" className="new-artist-label">
                        PDF:
                    </label>
                </div>
                <div className="new-artist-input-div">
                    <input
                        ref={fileInputPdf}
                        name="pdf"
                        onChange={handlePdf}
                        type="file"
                        className="new-artist-input new-artist-file"
                    />
                    <div className="new-artist-file-button-div">
                        <button
                            className="new-artist-upload-btn"
                            onClick={fileButtonClickPdf}
                        >
                            File
                        </button>
                        {state.pdf && (
                            <p id="file-link-name">{state.pdf.name}</p>
                        )}
                    </div>
                </div>
            </div>

            <div className="new-artist-submit">
                <button
                    className="new-artist-submit-button"
                    onClick={handleClick}
                >
                    Save
                </button>
            </div>
        </form>
    );
}

export default EditArtist;
